import React from 'react'
import ReactPaginate from "react-paginate";
import RightArrow from "../illustrations/rightArrow";
import LeftArrow from "../illustrations/leftArrow";
import { PaginationWrapper } from './pagination.style';

function Pagination({call, pageCount, range, setCurrentPage}) {
  return (
    <PaginationWrapper>
      <div>
        <ReactPaginate
          breakLabel="..."
          className="pagination"
          nextLabel={<RightArrow />}
          nextLinkClassName="next-page"
          previousLinkClassName="previous-page"
          onPageChange={(e) => {
            if (call) {
              call(e.selected + 1);
            }
            console.log(e.selected + 1);
            setCurrentPage(e.selected + 1);
          }}
          pageClassName="page-link"
          activeClassName="active-page-link"
          pageRangeDisplayed={range}
          pageCount={pageCount}
          previousLabel={<LeftArrow />}
          renderOnZeroPageCount={null}
        />
      </div>
    </PaginationWrapper>
  );
}

export default Pagination
