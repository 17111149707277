/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
/* eslint-disable global-require */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, Suspense, lazy, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "@micmac/components";
import GlobalStyles from "./globalStyle";
import Routes from "./routes/routes";
import Layout from "./components/layout/layout";
import Navbar from "./components/navigation/navbar";
import { getUser, validateToken } from "./actions/user";
import AppWidget from "./components/widgets/appWidget";
import Error from "./pages/error/error";
import InstallPWA from "./components/pwa/install";

// eslint-disable-next-line import/no-unresolved
const Erm = lazy(() => import("@micmac/erm"));
// eslint-disable-next-line import/no-unresolved
const Rep = lazy(() => import("@micmac/rep"));
// eslint-disable-next-line import/no-unresolved
const Shop = lazy(() => import("@micmac/shop"));
// eslint-disable-next-line import/no-unresolved
const Election = lazy(() => import("@micmac/election"));
const isAuth = localStorage.sekatAuthStatus;
const token = localStorage.sekatToken;
const cooperative = localStorage.sekatCoop;
const phone = localStorage.sekatPhone;

// eslint-disable-next-line no-shadow
function App({ getUser, user, history, validateToken }) {
  const { pathname } = history.location;
  const [navLink, setNavlink] = useState(null);
  const [appName, setAppName] = useState("");
  const validatorRedirect = () => {
    localStorage.sekatAuthStatus = false;
    window.location.href = "/signin";
  };

  useEffect(() => {
    if (isAuth === "true") {
      const obj = JSON.parse(cooperative);
      validateToken(obj.value, token, phone)
        .then((res) => {
          if (res.code === "200" && res.object) {
            if (res.object.isTokenCorrect) {
              getUser(token, obj.value);
            } else {
              toast.error("Failed to validate web token");
              validatorRedirect();
            }
          } else {
            toast.error("Failed to validate web token");
            validatorRedirect();
          }
        })
        .catch(() => {
          toast.error("Oops! something went wrong");
          validatorRedirect();
        });
    }
  }, [isAuth]);
  useEffect(() => {
    if (user.error) {
      validatorRedirect();
    }
  }, [user]);

  useEffect(() => {
    setAppName(localStorage.sekatApp);
    // Navlink selector
    if (localStorage.sekatApp === "erm") {
      // eslint-disable-next-line global-require
      const links = require("./data/ermLinks");
      setNavlink(links.default);
    }

    if (localStorage.sekatApp === "rep") {
      // eslint-disable-next-line global-require
      const links = require("./data/repLinks");
      setNavlink(links.default);
    }
    if (localStorage.sekatApp === "election") {
      const links = require("./data/electionLinks");
      setNavlink(links.default);
    }
    if (
      !localStorage.sekatApp ||
      localStorage.sekatApp === "undefined" ||
      localStorage.sekatApp === "shop"
    ) {
      setNavlink([]);
    }
  }, [localStorage.sekatApp, pathname]);

  let view = (
    <div>
      <Routes />
    </div>
  );
  let appView;
  if (localStorage.sekatApp === "erm") {
    // eslint-disable-next-line no-unused-vars
    appView = (
      <Suspense fallback={<Loader />}>
        <Erm user={user} />
      </Suspense>
    );
  }
  if (localStorage.sekatApp === "rep") {
    // eslint-disable-next-line no-unused-vars
    appView = (
      <Suspense fallback={<Loader />}>
        <Rep user={user} />
      </Suspense>
    );
  }
  if (localStorage.sekatApp === "shop") {
    // eslint-disable-next-line no-unused-vars
    appView = (
      <Suspense fallback={<Loader />}>
        <Shop user={user} />
      </Suspense>
    );
  }
  if (localStorage.sekatApp === "election") {
    // eslint-disable-next-line no-unused-vars
    appView = (
      <Suspense fallback={<Loader />}>
        <Election user={user} />
      </Suspense>
    );
  }
  if (!localStorage.sekatApp || localStorage.sekatApp === "undefined") {
    appView = <Error />;
  }
  if (isAuth === "true") {
    if (user.user) {
      view = (
        <Layout>
          {(pathname === "/" || appName !== "shop") && (
            <Navbar links={navLink} />
          )}
          <div
            className="pages"
            style={{
              marginLeft:
                (pathname === "/" && "0px") || (appName === "shop" && "0px"),
            }}
          >
            {pathname === "/" ? <Routes isAuth={isAuth} /> : appView}
            <AppWidget />
          </div>
        </Layout>
      );
    } else {
      view = <Loader />;
    }
  }
  return (
    <div className="App">
      <InstallPWA />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <GlobalStyles />
      {view}
    </div>
  );
}

App.propTypes = {
  getUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  validateToken: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default withRouter(
  connect(mapStateToProps, { getUser, validateToken })(App)
);
